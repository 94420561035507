
/* HEADER 
----------------------------------------------------------*/
.header-top-row {
	background: $charcoal-900; 
	color: white; 
	padding: 15px 25px;

    @include media-breakpoint-down(lg) {
        padding: 15px 0;
    }
}
.header-online-start {
	font-size:1.5rem;
	line-height: 1.2;
	background: $mb-gold-2;
	color: $charcoal-800;
	font-weight: 700;

    @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
    }

}
.header-online-promo {
	background: $mb-gold-2;
	color: $charcoal-800;
	font-weight: 700;
}
.header-online-promo a {
	position: absolute;
	top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.header-online-promo .order-online-new {
	background: $green;
	color: $white;
}
.header-search {

    border-bottom: 1px solid $mb-gold-2; 
    border-radius: 1px; 
    display: flex;

    .search-input {
        border: none; 
        background-color: transparent; 
        color: $white; 
        width: calc(100% - 220px);
        margin-right: 10px;
        font-size: 1.4rem; 
        font-weight: 300; 

        &:focus {
            outline: none;
        }

        @include media-breakpoint-down(lg) {
            font-size: 1.125em;
        }
    }

    .select-store {
        width: 120px;
        position: relative; 
        left: -1px; 
        bottom: 3px;
    }

    #searchSubmit {
        background: $mb-gold-1; 
        width: 50px; 
        position: relative; 
        left: 0px; 
        bottom: 3px;

        &:hover {
            background: $mb-gold-2; 
        }
    }

    .mb-search-icon {
        position: relative; 
        top: 1px;
        font-size: 21px;
    }
}

#CartLink {
	color: $white;
}

.cartBadge {
	color: $black !important;
	margin-left: 3px;
	background-color: $mb-gold-2;
	border-radius: 10px;
	padding: 3px 7px;
    position: relative;
    top: -1px;
}

.header-nav-row {
	background: $black;
}

.navbar-brand img {
	width: 180px;
    height:auto;

    @include media-breakpoint-up(xl) {
        width: 275px;
    }
}

/* FOOTER 
----------------------------------------------------------*/
#footer {
	color: $charcoal-700;

    @include media-breakpoint-down(lg) {
        padding-top: 30px;
        text-align: center;
    }

    .footer-contact-row {
        border-top: 1px solid $gray; 
        border-bottom: 1px solid $gray;
    }

    h2.footer-phone {
        font-size: 32px;
        font-weight:900;
    }

    h3 {
        font-size: 18px;
    }

    a {
        color: $charcoal-700;

        &:hover {
        opacity: 0.85;
        }
    }

    ul.list-unstyled {
        line-height: 1.4em;
        margin-bottom: 20px;
        font-size: 14px;
    }

    ul.list-unstyled li {
        margin-bottom: 10px;
    }

    .underline a {
        text-decoration: underline;
    }

    .socialbar.list-inline > li {
        padding: 0;

        > a {
            color: $charcoal-750;
            font-size: 1.3em;

            @include media-breakpoint-down(lg) {
                font-size: 1.2em;
            }

            &.fb {
                color: #4e72a4;
            }
            &.tw {
                color: #0ab9e7;
            }
            &.yt {
                color: #cc3139;
            }
            &.gp {
                color: #e63b28;
            }
            &.li {
                color: #0d87ad;
            }
            &.pi {
                color: #cd323a; 
            }
        }
    }

    .socialbar .list-inline-item:not(:last-child) {
        margin-right: 0;
    }

    .container:first-child {
         @include media-breakpoint-down(lg) {
            text-align: left;
		    padding-left: 30px;
		    margin-bottom: 20px;
        }
    }

    .list-inline {
        @include media-breakpoint-down(lg) {
            text-align: center;
            margin-bottom: 15px;
        }
    }
}