// Transitions
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Font size
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

$important: false;

@mixin font-size($size, $important: false) {
    @if $important == true {
        font-size: $size !important;
        font-size: calculateRem($size) !important;
    } 
    @else {
        font-size: $size;
        font-size: calculateRem($size);
    }

}