.mb-modal-header {
    @extend .modal-header; // bootstrap4 modal.scss
    background-color: $blue;
    color: $white;
    border-radius: 0;

    .close {
        color: $white;
    }
}

.mb-modal-title {
    @extend .modal-title; // bootstrap4 modal.scss
    font-weight: 400;
}

.mb-modal-footer {
    @extend .modal-footer; // bootstrap 4 modal.scss
    background-color: $surface-dark;
    border-top: solid 1px $light-gray;
}