/* HOME PAGE 
----------------------------------------------------------*/

.carousel {

    .carousel-inner .carousel-item {
        height: 500px;

        @include media-breakpoint-down(md) {
            height: 365px;
        }

        .carousel-image {
            background-size: cover;
            height: 100%;
            background-position: center center;
        }

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 20;
        }
    }

    .carousel-caption {
        background: rgba(0,0,0,0.7);
        padding:15px 40px;

        @include media-breakpoint-down(md) {
            right: 0;
            bottom: 0;
            left: 0;
            padding: 15px 20px;
            border-radius: 0 !important;
        }

        h2 {
            font-family: Bitter, sans-serif;
            font-weight: 700;
            padding: 0 0 0.3em;
            border-bottom: 1px solid $mb-gold-2;

            @include media-breakpoint-down(md) {
                font-size: 1.5em;
            }
        }

        p {
            @include media-breakpoint-down(md) {
                font-size: 0.9em;
            }
        }
    }

    .carousel-indicators {
        @include media-breakpoint-down(md) {
            margin-bottom: 0.5rem;
        }
    }
}

.home-location-info {

    > div {
        background-color: $mb-gold-1;
    }

    h2 {
        font-weight: 700;
        font-family: Bitter, sans-serif;
    }
}

.home-facebook-embed {
	max-width:500px;
    margin-left:auto;
    margin-right:auto;
}

.home-facebook-embed h4 {
	background: #1877F2;
	color: $white;
	border-bottom: 1px solid $mb-gold-1;
}

.home-hotbuys-flyer > div {
	background: #cd0e2d;
    color: $white;
    border-bottom: 1px solid $mb-gold-1;
}