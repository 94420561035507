.item_details_table>tbody>tr>td {
    padding: 2px 6px
}

.item_details_table>tbody {
    font-size: 12px
}

.pickup-details {
    font-size: 1rem;
    color: #3b3c3c;
    line-height: 1.2;
}

.input-group>.form-control, .input-group>.form-control-plaintext, .input-group>.custom-select, .input-group>.custom-file {
    position: relative;
    flex: .8 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

#itemDetailContainer .quantity-container {
    width: 100%
}

#add-to-cart-btn {
    font-weight:600;
    width:100%
}

#itemDetailContainer .carousel .carousel-inner .carousel-item {
    height: auto;
}

#itemDetailContainer .carousel-indicators button {
    background-color: #000;
}

#itemDetailContainer .carousel-indicators .active {
    background-color: #555;
}

#itemDetailContainer .carousel-control-prev-icon,
#itemDetailContainer .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, .5);
    padding: 10px;
    background-clip: padding-box;
}

#itemDetailContainer .carousel-control-prev-icon:after,
#itemDetailContainer .carousel-control-next-icon:after {
    color: white;
    font-size: 20px;
}

/* For screen sizes over 1200px */
@media (min-width: 1200px) {
    .sticky-card {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }
}

/* For screen sizes 1200px and below */
@media (max-width: 1200px) {
    .sticky-card {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1000;
        background-color: #fff;
        /* Adjust as needed to match the card's background */
        text-align: center;
        border: none;
        border-top: 2px solid #ccc;
        margin-bottom: 0 !important
    }
    .quantity-container {
        display:block !important;
    }
}