.btn-info {
	font-weight: 900;
	background: $light-gray;
	border-color: $light-gray;
	border-radius: 10px;
	color: $charcoal-750;

    &:hover {
        background: $charcoal-750;
        color: $light-gray;
        border-color: $charcoal-750;
    }
}

.navbar-custom {
    background-color: $black;

    .navbar-brand,
    .navbar-text {
        color: $white;
    }

    .navbar-nav .nav-link {
        color: $white;
        font-weight: 400;

        &:focus,
        &:hover {
            color: $mb-gold-1;
        }
    }
}

.navbar-dark {

    .navbar-toggler {
        border-color: rgba(255,255,255,.25);
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
}

#headerTop .header-nav-row .dropdown-menu {
	background: $mb-gold-2;
	color: #3a3a3a;
    border:none;
    
    .dropdown-item {

        &:hover,
        &:focus {
            background: $mb-gold-1;
        }
    
        &:active {
            background: $charcoal-900;
            color: $white;
        }
    
    }
}

.bootstrap-touchspin {

    .btn-primary {
        color: $black;
        background-color: $surface-darker;
        border-color: $gray;

        &:hover {
            background-color: $gray;
        }
    }
}