@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Bitter:700');

body {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1.25rem;
	color: $charcoal-750;
	background: $white;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

a {

	&:hover,
	&:active,
	&:focus {
		color: $charcoal-750;
		text-decoration: none;
	}
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}

button {
	transition: all 0.2s ease;
}

.btn-mb-gold,
.btn-mb-gold:visited {
	background-color: #FFD200 !important;
}

.btn-mb-gold:hover,
.btn-mb-gold:active {
	background-color: #FFEA00 !important;
}

h1.page-heading {
	font-family: Bitter, sans-serif;
	font-weight: 700;
	padding-bottom: 4px;
	border-bottom: 2px solid $mb-gold-2;
	margin-bottom: 0.7em;
}

.container-content-padding {
	padding-top: 25px;
	padding-bottom: 15px;
}

.bg-mb-light-gray {
	background-color: $light-gray;
}

.bg-mb-dark-gray {
	background-color: #585854;
}

.bg-mb-yellow-gradient {
	background: $mb-gold-2;
	background: linear-gradient(90deg, rgba(251, 191, 14, 1) 0%, rgba(255, 210, 0, 1) 50%, rgba(251, 191, 14, 1) 100%);
}

.spaced-list li {
	padding: 0.25em 0;
}

#headerTop {
	min-height: 167px;

	&.global-alert-shown {
		min-height: 279px;
	}
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.rounded-lg {
	border-radius: 0.3rem
}

.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-primary {
    color: #fff;
}

.responsive-map {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}

.loaderWithSpinner,
.loaderWithSpinnerCore {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: url('/images/loader2.gif') no-repeat center 50% rgba(255, 255, 255, 0.5);
	z-index: 9999;
	display: none;
	background-size: 80px;

	&.spinner-top-positioning {
		background-position: center 100px;
		background-size: 80px !important;
	}
}

.tool-tip-indicator {
	top: -35px;
	left: 50%;
	margin-left: -15px;
	font-size: 20px;
	text-align: center;
	border: 1px solid $gray;
	border-radius: 4px;
	background-color: $gray;
	width: 30px;
	position: absolute;
	display: none;
	z-index: 9001;

	* {
		margin-bottom: 3px;
	}

	&:before {
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $gray;
		content: '';
		top: 100%;
		position: absolute;
		left: 50%;
		margin-left: -6px;
	}

	&.success {
		background-color: $green;
		color: $white;
		border-color: $green;

		&:before {
			border-top-color: $green;
		}
	}

	&.error {
		background-color: $red;
		color: $white;
		border-color: $red;

		&:before {
			border-top-color: $red;
		}
	}

	&.item-quantity-indicator {
		left: 40%;
	}
}

.cart-quantity {
	width: 60px;
	text-align: center;
}

.input-group>.input-group-prepend>.btn {
	border-top-right-radius: 0;
    border-bottom-right-radius: 0;
	z-index:1
}

.input-group>.input-group-append>.btn {
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.input-group-prepend {
	margin-right:-1px
}

.input-group>.input-group-append {
	margin-left:-1px
}

#itemResultDropdown {
	width: 100%;
	display: inherit !important;
	background-color: $surface-base;
	z-index: 1030;

	.suggestion-column {
		border-right: 1px solid $gray;
		margin-bottom: 4px;

		&:last-child {
			border-right: transparent;
		}
	}

	.dropdown-header {
		text-align: center;
		color: $charcoal-975;
		background-color: $light-gray;
		font-weight: 600;
		margin-bottom: 4px;
	}

	.dropdown-content {
		max-height: 525px !important;
		overflow-y: initial;

		li {
			background-color: inherit;
			height: 32px;

			a {
				padding: 4px !important;

				&:hover {
					background-color: $surface-darker;
				}
			}
		}
	}
}

/* Newsletter Sign-up Form */
.hbspt-form .hs-button {
	background: #ffd200;
    border-color: #ffd200;
    color: #202325;
    font-size: 18px;
    line-height: 18px;
    width: auto;
    font-weight: 700;
    padding: 12px 24px;
}
.hbspt-form .actions {
	margin: 20px 0;
}
.hbspt-form fieldset.form-columns-2 {
	max-width:100%
}
#emailSignup p {
    border-bottom: 1px solid #81827E;
    padding-bottom: 15px;
}
#emailSignup .hs-error-msgs {
	margin-bottom:0;
	color: #FFD200;
	list-style-type: none;
	padding-left:0;
}

#footer h3.h3 {
	font-size: 1.75rem;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}