/* TABLES 
----------------------------------------------------------*/
.table thead {
	background-color: $light-gray;
}

.table-bordered>tbody>tr>td,
.table-bordered>thead>tr>th {
	border: none;
}

.table-striped > tbody > tr {

    &:nth-of-type(odd) {
        background-color: $white;
    }

    &:nth-of-type(even) {
        background-color: $surface-darker;
    }
}