/* Datepicker Component Start */

.ui-datepicker {
	
	.ui-state-default {
		border: none !important;
	}
	
	.ui-state-active {
		background-color: $blue-dark-2 !important;
	}
}

.ui-datepicker-header {
	background-color: $blue-dark-2 !important;
	color: $white !important;
	
	a {
		background-color: $white !important;
	}
}

.date-picker-toggle-options {
	display: flex;

	@include media-breakpoint-up(md) {
		display: none;
	}

	@include media-breakpoint-down(lg) {
		.modal-body & {
			display: flex;
		}
	}

	button {
		flex: 1;
	}
}

/* Datepicker Component End */

.calendar-right {
	right: 0;
}