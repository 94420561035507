.card-body table.table {
    margin-bottom: 0;
}

/* Ensure consistent spacing in the card grid */
.row-cols-1 .col,
.row-cols-md-2 .col,
.row-cols-lg-3 .col {
    display: flex;
    flex-direction: column;
}

.table>:not(caption)>*>* {
    color: inherit;
    background-color: inherit;
}

table.table-striped>tbody>tr.custom-bg-1 {
    background-color: #941114 !important;
    color: #fff !important;
}

table.table.table-striped tr.custom-bg-2 {
    background-color: #F6911E !important;
    color: #fff !important;
}

table.table.table-striped tr.custom-bg-3 {
    background-color: #D0501B !important;
    color: #fff !important;
}

table.table.table-striped tr.custom-bg-4 {
    background-color: #D1232A !important;
    color: #fff !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: none;
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #e6e6e6;
}
.table-striped>tbody>tr:nth-of-type(even) {
    background-color: #fff;
}