
.srchItemTable {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 5px;
	border-bottom: none;

	tr td {
		display: block;
		text-align: center;
		padding: 10px 10px 10px 10px;
		border-width: 0 1px 0 1px;
		border-style: solid;
		border-color: $surface-darker;

        &:first-child {
            border-width: 1px 0 1px 1px;
            border-style: solid;
            border-color: $surface-darker;
            padding-left: 20px;

            @include media-breakpoint-down(lg) {
                border-width: 1px 1px 0 1px;
                border-style: solid;
                border-color: $surface-darker;
                padding-left: 20px;
            }
        }
        
        &:last-child {
            border-right: 1px solid $surface-darker;
            padding-right: 20px;
            margin-bottom: 0px;

            @include media-breakpoint-down(lg) {
                border-bottom: 1px solid $surface-darker;
                margin-bottom: 20px;
            }
        }
        
	}

	span {

		&.item_description,
		&.addl_desc {
			display: block;
		}
		&.desc_vendor,
		&.desc_size {
			padding-right: 5px;
		}
	}
}

.srchItemTableGrid {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 5px;
	border-bottom: none;

	tr {
		float: left;
		
		padding-left: 5px;

		@include media-breakpoint-up(xs) {
			width: 50%;
		}

		@include media-breakpoint-up(xl) {
			width: 33.3%;
		}

		td {
			display: block;
			text-align: center;
			padding: 10px 10px 10px 10px;
			border-width: 0 1px 0 1px;
			border-style: solid;
			border-color: $surface-darker;

			&:first-child {
				border-width: 1px 1px 0 1px;
				border-style: solid;
				border-color: $surface-darker;
				height: 135px;
			}

			&:last-child {
				border-bottom: 1px solid $surface-darker;
				margin-bottom: 20px;
				height: 60px;
			}

			&.td_desc {
				height: 100px;

				@include media-breakpoint-down(xs) {
					height: 140px;
				}

				@include media-breakpoint-between(lg, xl) {
					height: 140px;
				}
				
				line-height: 1.25em;
			}

			&.td_price {
				height: 35px;
				font-weight: 700;
			}

			div.bootstrap-touchspin {
				width: 125px;
				margin: 0 auto;
				float: none;
			}

			span.addl_desc {
				display: none;
			}
		}
	}

	span {
		&.item_description {
			display: block;
		}
		&.desc_vendor {
			color: gray;

			&::after {
				content: " | ";
			}
		}
		&.desc_size {
			color: gray;
		}
		&.desc_servings {
			display: none;
		}
	}

	.imgMedium {
		margin: 0 auto;
	}

	.srchLabel {
		display: none;
	}
}

.bootstrap-touchspin {
	width: 125px;
	float: right;
	margin: 0;

	@include media-breakpoint-down(lg) {
		margin: 0 auto;
		float: none;
	}
}

.srchLabel {
	font-weight: bold;
	margin-right: 3px;
	line-height: 1em;
}

.imgMedium {
	width: 115px;
	height: auto;
	margin: 7px 11px 7px 0;
}


.add_cart,
.cart-quantity {
	text-align: center;
	-moz-appearance:textfield;
}

.price {
	font-size: 18px;
	font-weight: bold
}

.item_description {
	color: $blue;
	text-decoration: none;
	cursor: pointer;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.2em
}

.filter-group {
    margin-bottom: 1.5rem;
}

.filter_container {
	max-height: 250px;
    overflow: hidden;
    border: solid 1px $surface-darker;

	&:hover {
		overflow-y: auto;
	}

    ul {
        padding: 0.25rem;
        margin: 0;

        li label {
            font-size: 14px;
        }
    }
}

.filter-box .filter-heading {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.cart_summary_container {
	max-height: 500px;
	border: 1px solid $surface-darker;
	margin-bottom: 20px;
	overflow: auto;
}

.view-cart-container {
    border: 1px solid $surface-darker;
	margin-bottom: 20px;
}

table.cart_summary {
	margin-bottom: 0rem;
	
	td {
		border-top: none;
	}
}

.scroller {
	scrollbar-color: $charcoal-600 white;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 9px;
	}
	&::-webkit-scrollbar-track {
		background: white;
	}
	&::-webkit-scrollbar-thumb {
		background: $charcoal-600;
		border-right: 1px solid white
	}
}

.call_ahead {
	color: #FF1A1A;
	font-weight: bold;
}

.item_weight {
	font-size: 12px;
	color: grey;
}

.cart_total span {
	float: right;
	font-weight: bold;
	font-size: 22px;
	padding-right: 10px
}

.input-group-prepend .btn,
.input-group-append .btn {
	z-index: 0;
}

#srchItemTableSort {
	float: left;

	.fa-th,
	.fa-list {
		font-size: 1.7em;
		color: $charcoal-700;
		padding: 0 8px 1px 0;
		cursor: pointer;
		vertical-align: middle;

		&:hover {
			color: $charcoal-750;
		}
	}

	span.view_active .fa {
		color: $primary-color;
	}
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.subcategory-browse {
	display: none;
}

.category_filters .subcategory-browse {
	display: block;
	margin-bottom: -12px;

	p {
		background: $mb-gold-2;
		padding: 8px 10px;
		margin: 0;
		font-size: 0.8em;
	}
	
	div {
		color: $mb-gold-2;
		font-size: 2rem;
		margin: 0 0 0 10px;
		line-height: 20px;
		margin-top: -2px;
	}
}

.table {
    --bs-table-striped-bg: #fff
}

@include media-breakpoint-up(xl) {
	.srchItemTable tr td {
		vertical-align: middle;
		display: table-cell;
		text-align: left;
		border-width: 1px 0 1px 0;
		border-style: solid;
		border-color: $surface-darker;
	}

	.srchItemTable tr:last-child {
		width: 50px;
	}
}

@include media-breakpoint-up(lg) {

	.cart_summary_total {
		position: static;
		width: 100%;
		padding: 0
	}
	#srchItemTableSort {
		float: right;
	}

    
    .imgContainer {
        height: 120px;
        overflow: hidden;

        img {
            cursor: pointer;
        }
    }
}

.paging {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include font-size(14px);
    border-top: solid 1px $gray;
    padding-top: 0.5rem;

    .paginationButtons {
        margin-right: 0.25rem;
        margin-left: 0.25rem;

        .material-icons {
            font-size: 21px;
        }

        &.seekFirst {
            margin-right: 0;
        }

        &.seekEnd {
            margin-left: 0;
        }
    }
    
    .resultPaging-right {
        display: flex;
        align-items: center;
        text-align: right;

        .dropdown-menu {
            width: 80px;
            min-width: 80px;
            max-height: 200px;
            overflow: auto;
        }
    }
}

#searchResultsContainer {
	flex: 1;
}

#searchWrap.no-results {
	#searchResultsContainer, #searchFilters, #searchFilterToggle {
		display: none !important;
	}
}

@include media-breakpoint-up(lg) {
	#searchFilters {
		flex: 0 0 250px;
		display: block;
	}

	#cartWrap {
		flex: 0 0 350px;
	}
}


@include media-breakpoint-down(md) {
	#cartWrap .cart-summary-actions {
		position: fixed;
		bottom: 0;
		background: $surface-dark;
		padding: 1rem;
		width: 100%;
		left: 0;
		border-top: solid 1px $gray;
		z-index: 1;
	}
}
