/* HOT BUYS COMMON
----------------------------------------------------------*/
.hotbuys-image {
    width: 45%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    float: left;
    border: 1px solid $surface-base;
}
.hotbuys-text {
    margin-left: 50%;
}

.hotbuys-item .bootstrap-touchspin {
    margin-bottom: 0.5rem;

    .hotbuys-home & {
        margin-top: 0.5rem;
    }
}




/* HOT BUYS HOME PAGE
----------------------------------------------------------*/

.home-hotbuys {
    background: $red;
    color: $white;
    
    .hotbuys-header .hotbuys-item-inner {
        background: none;
        padding: 15px 0;
        text-align: right;

        p {
            background: $red-dark-2;
            padding: 5px 15px;
        }
    } 
    
    .hotbuys-item {
        padding: 5px;



        h3 {
            font-size: 18px;
            color: $black;
        }

        h2 {
            font-size: 28px;
            margin-bottom: 0.25rem;
            font-weight: 800;
            color: $red;
        }

        p {
            font-size: 12px;
            margin-bottom: 0.25rem;
            color: $charcoal-700;
        }
    }

    .hotbuys-item-inner {
        height:100%;
        padding: 10px;
        background: $white;
    }
}


/* HOT BUYS ORDER ONLINE PAGE
----------------------------------------------------------*/

.order-online-hotbuys {

    .hotbuys-item {
        padding: 10px;

        h2 {
            font-size: 36px;
            margin-bottom: 0.25rem;
            font-weight: 800;
            color: $red;
        }

        h3 {
            font-size: 22px;
        }

        h5 {
            font-size: 14px;
            margin-bottom: 0.25rem;
            color: $charcoal-400;
        }
    }

    .hotbuys-item-inner {
        height: 100%;
        padding: 10px;
        border: 3px solid $red;
    }
}

.hotbuys-item h3 a  {
	color: $charcoal-750 !important;
}


@include media-breakpoint-down(xs) {
    .fb-page span, .fb-page iframe {
        width: 100% !important;
    }
}
