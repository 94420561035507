.category-listing {
    
    .card {
        width: 200px;
        border: none;

        .card-img-overlay {
            padding:0;
        }

        .card-title {
            font-size: 1.2rem;
            text-shadow: 0 0 5px black;
            margin-bottom:0;
            background:rgba(0,0,0,0.4);
            padding:0.75rem;
            width:100%;
        
        }
        &:hover {

            .card-title {
                background: $mb-gold-2;
                color: $black;
                text-shadow: none;
                padding: 0.25rem;
                padding-top: 0.75rem;
            }

            .card-img-overlay {
                border: 0.5rem solid $mb-gold-2;
            }
        }
    }
}

