%mb-button-style {
    @extend .btn; // bootstrap4 buttons.scss
    box-shadow: none;
    text-transform: inherit;
    border-radius: 2px;
    border-width: 1px;
    font-size: 14px;

    &:hover {
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.16);
        outline: none;
    }
    
    &:active {
        outline: none;
    }
}

%mb-button-outline-style {
    @extend .btn;
    color: $charcoal-900 !important;
    text-decoration: none !important;
    border-color: $gray;
    outline: none;
    box-shadow: none;
    border-radius: 2px;
    border-width: 1px;
    font-size: 14px;

    &:hover:not(.disabled):not(:disabled) {
        background: none;
        color: $charcoal-900;
        border-color: $gray;
    }

    &:active {
        background-color: $light-gray !important;
        color: $charcoal-900 !important;
        border-color: $gray !important;
    }

    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

// default gray button
.mb-button-outline {
    @extend %mb-button-outline-style;
}

.mb-button-outline-green {
    @extend %mb-button-outline-style;
    border-color: $green-light-2;
    background-color: $green-light-4;

    &:hover:not(.disabled):not(:disabled) {
        background-color: $green-light-3;
    }

    &:active {
        background-color: $green-light-3 !important;
    }
}

// default gray button
.mb-button {
    @extend %mb-button-style;
    color: $charcoal-900;
    border-color: $gray;
    background-color: $surface-base !important;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.05) 100%);

    &:hover:not(.disabled):not(:disabled) {
        background-color: $surface-base;
        background: none;
        color: $charcoal-900;
        border-color: $gray;
    }

    &:active {
        background-color: $light-gray !important;
        color: $charcoal-900 !important;
        border-color: $gray !important;
    }

    &:disabled,
    &.disabled {

        &:hover {
            box-shadow: none;
        }
    }

    &-pagination {
        @extend .mb-button;
        width: 30px;
        height: 30px;
        padding: 0;
        vertical-align: middle;
    }
}

// default blue button
.mb-button-blue {
    @extend %mb-button-style;
    color: $white;
    border-color: $blue-dark-2;
    background-color: $blue !important;
    background: linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%);

    &:hover:not(.disabled):not(:disabled) {
        background: rgba(255,255,255,0.1);
        color: $white;
    }

    &:active {
        background-color: $blue-dark-2 !important;
        box-shadow: none !important;
        color: $white !important;
    }

    &:focus {
        color: $white !important;
    }

    &.disabled,
    &:disabled {
        opacity: 0.45;

        &:hover {
            box-shadow: none;
            color: $white;
        }
    }
}

// default red button
.mb-button-red {
    @extend %mb-button-style;
    color: $white;
    border-color: $red-dark-4;
    background: linear-gradient(180deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.2) 98.52%);
    background-color: $red !important;

    &:hover:not(.disabled):not(:disabled) {
        background: rgba(255,255,255,0.1);
        color: $white !important;
    }

    &:active {
        background-color: $red-dark-2 !important;
        box-shadow: none !important;
        color: $white !important;
    }

    &:focus {
        color: $white !important;
    }

    &.disabled,
    &:disabled {
        opacity: 0.45;

        &:hover {
            box-shadow: none;
            color: $white;
        }
    }
}

// default green button
.mb-button-green {
    @extend %mb-button-style;
    color: $white;
    border-color: $green-dark-4;
    background: linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.2) 100%);
    background-color: $green !important;

    &:hover:not(.disabled):not(:disabled) {
        background: rgba(255,255,255,0.1);
        color: $white !important;
    }

    &:active {
        background-color: $green-dark-2 !important;
        box-shadow: none !important;
        color: $white !important;
    }

    &:focus {
        color: $white !important;
    }

    &.disabled,
    &:disabled {
        opacity: 0.45;

        &:hover {
            box-shadow: none;
            color: $white;
        }
    }
}

// icon button currently used for item quantity component and in item search
.mb-button-icon {
    @extend %mb-button-style;
    border-color: $gray;
    color: $charcoal-600;
    padding: 3px 6px;
    vertical-align: initial;
    min-width: initial !important;

}

// Datatable button styles

.mbdatatable-buttons,
.pageheader-buttons {

    .mb-button,
    .mb-button-blue,
    .mb-button-green,
    .mb-button-red {
        padding: 0.25rem 0.75rem;

        .material-icons {
            font-size: 20px;
        }
    }

    .mb-button.btn-filter-open {
        color: $white;
        border-color: $blue-dark-2;
        background-color: $blue !important;
        background: linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%);

        &:hover {
            background: rgba(255,255,255,0.1);
            border-color: $blue-dark-2 !important;
            color: $white !important;
        }
    }
}

// Button sizes

.button-small {
    @extend .btn-sm;
}

.button-large {
    @extend .btn-lg;
}

// Misc Use Cases 

.add-order-guides-btn {
    &:hover:not(.disabled):not(:disabled) {
        cursor: pointer;
    }

    .material-icons {
        color: $charcoal-700;
    }

    span {
        text-decoration: underline;
        color: $charcoal-700 !important;
    }
}
.manage-order-guides-btn {

    &:hover:not(.disabled):not(:disabled) {
        cursor: pointer;
    }

    .material-icons {
        color: $green;
    }

    span {
        text-decoration: underline;
        color: $green !important;
    }

}

.panel-button {
    height: 57px;
    padding: 16px;
    background-color: $surface-darker;
    border-bottom: 1px solid $gray;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    color: $charcoal-700;

    &:hover {
        color: $charcoal-800;
    }

    &.border-right {
        border-right: solid 1px $gray !important;
    }

    &.border-left {
        border-left: solid 1px $gray !important;
    }

    > .material-icons {
        font-weight: 600;
    }
}

// jQuery UI Button styles 

.ui-dialog-buttonset button {
    border: 1px solid $gray;
    background: $surface-dark;
    font-weight: normal;
}

.mobile-edit {
    border-radius: 50%;
    padding: 0.5rem;

    &.status-edit {
        background-color: $blue;
    }

    &.status-locked {
        background-color: $charcoal-500;
    }

    &:hover {
        opacity: 0.9;
    }
}