/* CONTACT PAGES 
----------------------------------------------------------*/
.contactform {

    td {
        padding: 2px;

        &.labels_left label {
            display: inline;
        }
    }

    label {
        display: block;
        text-align: right;
    }

    input {
        border: 1px solid silver;
    }

    textarea {
        border: 1px solid silver;
    }

    .trick {
        display: none;
    }
}
