.mb-checkbox {

    padding: 0;

    input[type=checkbox] {
        display: none;
        &:checked {
            + label:before {
                background-color: $blue;
                border-color: $blue;
                color: $white;
            }
        }
        &:disabled {
            + label {
                cursor: not-allowed;

                &:before {
                    transform: scale(1);
                    border-color: $charcoal-400;
                    background-color: $charcoal-200;
                }
            }
        }
        &:checked:disabled {
            + label:before {
                cursor: not-allowed;
                transform: scale(1);
                background-color: $blue-light-3;
                border-color: $blue-light-3;
            }
        }
        + label {
            display: block;
            margin: 0.2em;
            cursor: pointer;
            padding: 0.2em;
            font-weight: 400;
            padding-left: 25px;
			position: relative;
			min-height: 20px;
            &:active:before {
                transform: scale(0);
            }
            &:before {
                font-family: "Material Icons";
                content: "\e5ca";
                border: 0.1em solid $charcoal-500;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;
                padding: 2px;
                vertical-align: bottom;
                color: transparent;
                transition: .2s;
                line-height: 15px;
                font-size: 14px;
                position: absolute;
				left: 0;       
                background: $white;
                font-weight: bold;
			}
			
			&.small {
				font-size: 12px;
				margin-bottom: 0;
				padding-bottom: 0;
				padding-left: 20px;

				&:before {
					width: 15px;
					height: 15px;
					line-height: 9px;
					font-size: 12px;
					padding: 2px 1px 2px 0.5px;
				}
			}
        }
    }
    li {
        list-style: none;
    }
}

.required-field {

	label:after {
		color: $red-dark-1;
		font-weight: bold;
		content: '*';
		display: inline-block;
		margin-left: 5px;
		font-size: 13px;
	}
}

.error-message {
	display: none;
	color : $red;
	font-size: small;
}

.invalid-input {
	input, select, textarea {
		border-color: $red;
	}

	label {
		color: $red;
	}

	.error-message {
		display: block !important;
	}
}

.quantity-container {
    width: 125px;
}