/* ALERT BANNER 
----------------------------------------------------------*/
.alert-wrap {
	background: $black; 
	padding-top: 5px;
	padding-bottom: 15px;
}
.alert-box {
	background: $surface-base;
	padding:0;
}
.alert-icon {
	background: $mb-gold-2;
	padding: 10px;
}
.alert-title {
	padding: 10px;
}